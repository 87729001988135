import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import Container from '../layout/Container'
import { StaticImage } from 'gatsby-plugin-image'

function CloudsBanner(props) {
  return (
    <Box width="full" overflow="hidden">
      <Box position="relative" zIndex={0} maxHeight={'220px'}>
        <StaticImage
          src="../../images/clouds.jpg"
          alt="clouds"
          layout="fullWidth"
        />
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          width="full"
          zIndex={10}
        >
          <Container height="full" py={12}>
            <Flex
              justifyContent="center"
              height="full"
              width="full"
              flexWrap="wrap"
              flexDirection="column"
            >
              <Box width={'full'} textAlign="center" color="primary">
                {props.children}
              </Box>
            </Flex>
          </Container>
        </Box>
      </Box>
    </Box>
  )
}

export default CloudsBanner
